import React, { JSX, useCallback, useState } from 'react'
import {
    IonAccordion,
    IonAccordionGroup,
    IonBackButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonText,
    IonTextarea,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { fetchCaseById, selectCaseById } from '../Case/CaseSlice'
import { useParams } from 'react-router-dom'
import { createAim, deleteAimById, selectAimById, updateAim } from './AimSlice'
import { Form, Formik, FormikValues } from 'formik'
import { useHistory } from 'react-router'
import AimTypeEnum from '../../utils/Enum/AimTypeEnum'
import { Aim } from './AimType'
import DeleteDialog from '../../components/DeleteDialog/DeleteDialog'

const AimEdit = (): JSX.Element => {
    const { patientId, aimId, caseId } = useParams<{
        patientId: string
        caseId: string
        aimId: string
    }>()
    const caseById = useAppSelector((state) => selectCaseById(state, +caseId))
    const aim = useAppSelector((state) =>
        aimId === 'new' ? null : selectAimById(state, +aimId)
    )
    const dispatch = useAppDispatch()
    const history = useHistory()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)

    const handleSubmit = useCallback(
        async (values: FormikValues) => {
            if (aim) {
                await dispatch(
                    updateAim({ data: values, id: aimId, caseId, patientId })
                )
            } else {
                await dispatch(createAim({ data: values, caseId, patientId }))
            }
            await dispatch(fetchCaseById({ id: caseId, patientId }))
            history.push(`/patients/${patientId}/cases/${caseId}`)
        },
        [aim, aimId, caseId, dispatch, history, patientId]
    )

    const handleDeleteDialogOpen = useCallback(() => {
        setDeleteDialogOpen(true)
    }, [])

    const handleDismissDeleteDialog = useCallback(() => {
        setDeleteDialogOpen(false)
    }, [])

    const handleDelete = useCallback(async () => {
        await dispatch(deleteAimById({ id: +aimId, patientId, caseId }))
        history.push(`/patients/${patientId}/cases/${caseId}`)
    }, [aimId, caseId, dispatch, history, patientId])

    const AnamnesticCaseAnalysis = (): JSX.Element => {
        return (
            <>
                {caseById?.anamnesticCaseAnalysis ? (
                    <IonAccordionGroup expand="inset" multiple={true}>
                        <IonAccordion value="first">
                            <IonItem slot="header">
                                <IonLabel>Anamnestische Fallanalyse</IonLabel>
                            </IonItem>
                            <div className="ion-padding" slot="content">
                                {caseById?.anamnesticCaseAnalysis}
                            </div>
                        </IonAccordion>
                        {caseById.anamnesticCaseAnalysisHint && (
                            <IonAccordion value="second">
                                <IonItem slot="header">
                                    <IonLabel>
                                        Relevante Hinweise aus der Anamnese und
                                        Planung der weiteren Untersuchung
                                    </IonLabel>
                                </IonItem>
                                <div className="ion-padding" slot="content">
                                    {caseById?.anamnesticCaseAnalysisHint}
                                </div>
                            </IonAccordion>
                        )}
                    </IonAccordionGroup>
                ) : (
                    <div className="ion-text-center ion-padding ion-margin">
                        <IonText>
                            Keine anamnestische Fallanalyse vorhanden
                        </IonText>
                    </div>
                )}
            </>
        )
    }

    const AimFieldset = (): JSX.Element => {
        return (
            <Formik initialValues={aim ?? ({} as Aim)} onSubmit={handleSubmit}>
                {({ values, setFieldValue, handleChange, submitForm }) => (
                    <Form>
                        <IonList inset>
                            <IonListHeader>
                                <IonLabel>Art des Ziels</IonLabel>
                            </IonListHeader>
                            <IonItem>
                                <IonCheckbox
                                    slot="start"
                                    aria-label="Patientenziel"
                                    checked={
                                        values.type === AimTypeEnum.TYPE_PATIENT
                                    }
                                    onIonChange={() =>
                                        setFieldValue(
                                            'type',
                                            AimTypeEnum.TYPE_PATIENT
                                        )
                                    }
                                />
                                <IonLabel>Patientenziel</IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonCheckbox
                                    slot="start"
                                    aria-label="Patientenziel"
                                    checked={
                                        values.type ===
                                        AimTypeEnum.TYPE_THERAPEUT
                                    }
                                    onIonChange={() =>
                                        setFieldValue(
                                            'type',
                                            AimTypeEnum.TYPE_THERAPEUT
                                        )
                                    }
                                />
                                <IonLabel>Therapeutenziel</IonLabel>
                            </IonItem>
                        </IonList>
                        <IonList inset>
                            <IonListHeader>
                                <IonLabel>Details</IonLabel>
                            </IonListHeader>
                            <IonItem>
                                <IonTextarea
                                    name="description"
                                    label="Beschreibung"
                                    labelPlacement="floating"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.description ?? ''}
                                ></IonTextarea>
                            </IonItem>
                            <IonItem>
                                <IonTextarea
                                    name="specific"
                                    label="Spezifisch"
                                    labelPlacement="floating"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.specific ?? ''}
                                ></IonTextarea>
                            </IonItem>
                            <IonItem>
                                <IonTextarea
                                    name="measurable"
                                    label="Messbar"
                                    labelPlacement="floating"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.measurable ?? ''}
                                ></IonTextarea>
                            </IonItem>
                            <IonItem>
                                <IonTextarea
                                    name="achievable"
                                    label="Ausführbar"
                                    labelPlacement="floating"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.achievable ?? ''}
                                ></IonTextarea>
                            </IonItem>
                            <IonItem>
                                <IonTextarea
                                    name="relevant"
                                    label="Realistisch"
                                    labelPlacement="floating"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.relevant ?? ''}
                                ></IonTextarea>
                            </IonItem>
                            <IonItem>
                                <IonTextarea
                                    label="Terminierbar"
                                    labelPlacement="floating"
                                    name="timebound"
                                    autoGrow={true}
                                    onIonChange={handleChange}
                                    value={values.timebound ?? ''}
                                ></IonTextarea>
                            </IonItem>
                        </IonList>
                        <IonList inset>
                            <IonItem button detail={false} onClick={submitForm}>
                                <IonLabel color="primary">Speichern</IonLabel>
                            </IonItem>
                            {aim && (
                                <IonItem
                                    button
                                    detail={false}
                                    onClick={handleDeleteDialogOpen}
                                >
                                    <IonLabel color="danger">
                                        Ziel löschen
                                    </IonLabel>
                                </IonItem>
                            )}
                        </IonList>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar mode="ios">
                    <IonButtons slot="start">
                        <IonBackButton
                            text=""
                            defaultHref={`/patients/${patientId}/cases/${caseId}`}
                        ></IonBackButton>
                    </IonButtons>
                    <IonTitle>{aim ? 'Ziele' : 'Neues Ziel'}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <AnamnesticCaseAnalysis />
                <AimFieldset />
            </IonContent>
            <DeleteDialog
                header="Möchten Sie das Ziel wirklich löschen?"
                onDelete={handleDelete}
                onDidDismiss={handleDismissDeleteDialog}
                isOpen={deleteDialogOpen}
            />
        </IonPage>
    )
}

export default AimEdit
